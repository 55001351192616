<template>
    <div class="flex flex-col sm:justify-center items-center pt-6 sm:pt-0 rounded-3xl">
        <div class="w-full sm:max-w-md p-10 bg-white overflow-hidden rounded-3xl flex-col justify-center items-center gap-2.5 inline-flex sm:min-w-[470px]">
            <div class="self-end">
                <slot name="close-btn"></slot>
            </div>
            <div class="flex-col justify-start items-center gap-12 inline-flex mb-8">
                <slot name="logo" />
                <div class="self-stretch flex-col justify-start items-start gap-3 flex">
                    <div class="self-stretch text-center text-teal-900 text-3xl font-semibold font-lora">{{ $t('Log in')}}</div>
                </div>
            </div>
            <slot />
        </div>
    </div>
</template>
<script setup>
</script>
